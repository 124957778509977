
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import { getQuestionCriteria, criteriaHasQuota, getCriteriaValue, getQuestionCriteriaValue, getViabilityIconName, getViabilityIcon } from '@/utils/viability.ts'
export default defineComponent({
  name : 'ViabilityElement',
  props:{
    text:{
      type: String,
      default: ''
    },
    value:{
      type: [Number, String],
      default: 0
    },
    cursor:{
      type:String,
      default:'default'
    },
    showCheck:{
      type: Boolean,
      default: false
    },
    showQuotaButton:{
      type: Boolean,
      default: false
    },
    showCriteriaButton:{
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'null'
    },
    quotas:{
      type: Array,
      default: null
    },
    categories:{
      type: Array,
      default: null
    },
    criterias:{
      type: Array,
      default: null
    }
  },
  computed:{
    ...mapGetters([
      'provinces',
      'regions',
      'nielsenAreas'
    ]),
    quotaArray(){
      let quotas: any = this.quotas
      if(quotas == null) return []
      for(let quota of quotas){
        quota.value = ""
        if(quota.genderCriteria != null && Object.keys(quota.genderCriteria).length > 0){
          quota.value = quota.genderCriteria.multipleVals[0] == 0 ? this.$t('suite_men') : this.$t('suite_women')
        }
        if(quota.provinceCriteria != null && Object.keys(quota.provinceCriteria).length > 0){
          let value = this.provinces.filter(function(p: any){return quota.provinceCriteria.multipleVals[0] == p.id})[0].value
          quota.value = quota.value.length > 0 ? quota.value + ", " + value : value
        }
        if(quota.regionCriteria != null && Object.keys(quota.regionCriteria).length > 0){
          let value = this.regions.filter(function(p: any){return quota.regionCriteria.multipleVals[0] == p.id})[0].value
          quota.value = quota.value.length > 0 ? quota.value + ", " + value : value
        }
        if(quota.nielsenCriteria != null && Object.keys(quota.nielsenCriteria).length > 0){
          let value = this.nielsenAreas.filter(function(p: any){return quota.nielsenCriteria.multipleVals[0] == p.id})[0].value
          quota.value = quota.value.length > 0 ? quota.value + ", " + value : value
        }
        if(quota.ageCriteria != null && Object.keys(quota.ageCriteria).length > 0){
          let value = quota.ageCriteria.from + "-" + quota.ageCriteria.to
          quota.value = quota.value.length > 0 ? quota.value + ", " + value : value
        }
        if(quota.categoryCriteria != null && this.categories.length > 0){
          for(let category of quota.categoryCriteria){
            const question = getQuestionCriteria(this.categories, category.categoryId, category.questionId)
            if(question != null){
              const value = question.clientText + "-" + question.values.filter(function(v: any){return v.id == category.multipleVals[0]})[0].value
              quota.value = quota.value.length > 0 ? quota.value + ", " + value : value
            }
          }
        }
      }
      return quotas
    }
  },
  data(){
    return{
      showQuotas: false,
      showCriterias: false
    }
  },
  methods:{
    getViabilityIcon(criteria: any){
      return getViabilityIcon(criteria, this.quotas)
    },
    getCriteriaValue(criteria: any){
      return getCriteriaValue(criteria, this.categories)
    },
    getQuestionCriteriaValue(value: any, criteria: any){
      return getQuestionCriteriaValue(this.categories, criteria.categoryCriteria[0].categoryId, criteria.categoryCriteria[0].questionId, value)
    }
  }
})
